<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
    <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-100">
    <body class="h-full">
    ```
  -->

    <div class="h-full-screen min-w-full">
        <Head>
            <Title>{{ title }}</Title>
            <Meta name="description" :content="title" />
        </Head>
        <Teleport to="body">
            <NotificationsHolder></NotificationsHolder>
        </Teleport>
        <PrimaryHeader></PrimaryHeader>
        <div
            :class="[
                env === 'dev' ? 'mt-32' : 'mt-24',
                'container mx-auto sm:px-6 lg:px-8 pt-8 pb-12',
            ]"
        >
            <slot />
        </div>

        <Footer v-if="isHomePage"></Footer>
    </div>
</template>
<script setup lang="ts">
import * as Sentry from "@sentry/nuxt";
import Footer from "@/components/Footer.vue";
import PrimaryHeader from "@/components/PrimaryHeader.vue";
import { useCourse } from "@/composables/course";
import { useApiFetch } from "@/composables/fetch";
import NotificationsHolder from "@/components/general/NotificationsHolder.vue";
const { $clientPosthog } = useNuxtApp();
const config = useRuntimeConfig();
const nuxtApp = useNuxtApp();
const { courseData } = useCourse();
const { useDefaultFetch } = useApiFetch();
const route = useRoute();
const isHomePage = route.path === "/";
const host = window ? window.location?.hostname : "";
const env = config.public?.env || "dev";
const domain = host.split(".")[0];
const title = ref("Golf Course Chatbot");
let subdomain = route.query?.subdomain || domain || "";
if (subdomain === "localhost") {
    subdomain = "highlandcreek";
}
useHead({
    htmlAttrs: {
        class: "light h-full bg-gray-50",
    },
    link: [
        {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/apple-touch-icon.png",
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/favicon-32x32.png",
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/favicon-16x16.png",
        },
        {
            rel: "manifest",
            href: "/site.webmanifest",
        },
    ],
});
const setHeaders = (courseData: any = {}) => {
    title.value = `${courseData.courseName || "CourseRev"} Chatbot`;
    document.title = title.value;
};

const {
    execute: fetchCourseDataCall,
    data,
    isFetching: fetchCourseDataLoading,
    error,
}: any = useDefaultFetch(`/chat/course/${subdomain}`).json();
watch(error, (error) => {
    console.error("Error fetching course data: ", error.value);
    const errorMessage = error.value?.message || "Error fetching course data";
    const errorBody =
        error.value?.response?.data?.message || "Error fetching course data";
    const params = new URLSearchParams({
        error_message: errorMessage,
        error_body: errorBody,
    });
    const captureError = new ChatError(
        `Error fetching course data on ${subdomain}`,
        {
            errorMessage,
            errorBody,
            subdomain,
        },
        "Error fetching course data",
    );
    Sentry.captureException(captureError);
    window.location.href = `/404?${params.toString()}`;
});
watch(data, (newData) => {
    if (newData) {
        courseData.value = newData;
        setHeaders(courseData.value);
        $clientPosthog?.register({
            courseName: courseData.value?.courseName,
            courseId: courseData.value?.courseId,
        });
        Sentry.setTag("courseName", courseData.value?.courseName);
    }
});
nuxtApp.hook("page:finish", () => {
    if (import.meta.client) {
        window.onNuxtLoading?.(false);
    }
});

const fetchPageConfig = () => {
    const encodedConfig = route.query?.config || "";
    if (encodedConfig) {
        const decodedConfig = atob(encodedConfig as string);
        console.log("decoded iframe config: ", decodedConfig);
        const config = JSON.parse(decodedConfig || "{}");
        console.log("parsed iframe config: ", config);
    }
};

onMounted(async () => {
    Sentry.setTag("subdomain", domain);
    Sentry.getCurrentScope().setTransactionName("DefaultLayout");
    fetchPageConfig();
    fetchCourseDataCall();
});
</script>
<style lang="css">
body {
    font-family: "Open Sans", Verdana, Geneva, Tahoma, sans-serif;
}
</style>
